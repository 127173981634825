import React, { useState, useEffect, FC } from 'react';
import { confirmSignIn } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { RoutePath } from '../navigation/route-path';
import { ScreenWrapper } from '../components/ScreenWrapper';

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .required('New password required')
    .min(8, 'Password must be at least 8 characters long'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match')
    .required('Confirm password required'),
});

const ChangePassword: FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { fromLogin } = location.state || {};

  useEffect(() => {
    if (!fromLogin) {
      navigate(RoutePath.LOGIN);
    }
  }, [fromLogin, navigate]);

  const handleChangePassword = async (newPassword: string) => {
    setLoading(true);

    try {
      await confirmSignIn({
        challengeResponse: newPassword,
      });

      toast.success('Password changed successfully', {
        position: 'top-right',
      });
      navigate(RoutePath.WELCOME);
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await handleChangePassword(values.newPassword);
    },
  });

  return (
    <ScreenWrapper>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <h2 className='mb-4 text-center'>Choose New Password</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className='mb-3'>
              <label htmlFor='newPassword' className='form-label'>
                New Password:
              </label>
              <input
                type='password'
                id='newPassword'
                name='newPassword'
                className='form-control'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className='text-danger'>{formik.errors.newPassword}</div>
              ) : null}
            </div>

            <div className='mb-3'>
              <label htmlFor='confirmPassword' className='form-label'>
                Confirm Password:
              </label>
              <input
                type='password'
                id='confirmPassword'
                name='confirmPassword'
                className='form-control'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div className='text-danger'>{formik.errors.confirmPassword}</div>
              ) : null}
            </div>

            <button type='submit' className='btn btn-primary mt-3'>
              Change Password
            </button>
          </form>
        </>
      )}
    </ScreenWrapper>
  );
};

export default ChangePassword;
