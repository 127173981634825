import React, { useState, useEffect, FC } from 'react';
import { signIn } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../navigation/route-path';
import { ScreenWrapper } from '../components/ScreenWrapper';
import useAuthCredentials from '../hooks/useAuthCredentials';

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Email required'),
  password: yup.string().required('Password required'),
});

const Login: FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { getAuthCredentials } = useAuthCredentials();

  useEffect(() => {
    const checkSession = async () => {
      const credentials = await getAuthCredentials();
      if (credentials) {
        navigate(RoutePath.WELCOME);
      }
    };

    checkSession();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    setLoading(true);
    try {
      const { nextStep } = await signIn({
        username: email,
        password,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH',
        },
      });

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        navigate(RoutePath.CHANGE_PASSWORD, {
          state: { email, fromLogin: true },
        });
        return;
      }

      navigate(RoutePath.WELCOME);
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await login(values.email, values.password);
    },
  });

  return (
    <ScreenWrapper>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <h2 className='mb-4 text-center'>Sign in</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className='mb-3'>
              <label htmlFor='email' className='form-label'>
                Email:
              </label>
              <input
                type='text'
                id='email'
                name='email'
                className='form-control'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='text-danger'>{formik.errors.email}</div>
              ) : null}
            </div>

            <div className='mb-3'>
              <label htmlFor='password' className='form-label'>
                Password:
              </label>
              <input
                type='password'
                id='password'
                name='password'
                className='form-control'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className='text-danger'>{formik.errors.password}</div>
              ) : null}
            </div>

            <button type='submit' className='btn btn-primary mt-3'>
              Sign In
            </button>
            <div className='mt-2'>
              <button
                type='button'
                onClick={() => navigate(RoutePath.FORGOT_PASSWORD)}
                className='btn btn-sm btn-link mt-auto p-0 text-md-start'
              >
                Forgot Password?
              </button>
            </div>
          </form>
        </>
      )}
    </ScreenWrapper>
  );
};

export default Login;
