import React, { useState, FC } from 'react';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../navigation/route-path';
import { ScreenWrapper } from '../components/ScreenWrapper';

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Email required'),
});

const codeValidationSchema = yup.object({
  code: yup.string().required('Verification code required'),
  newPassword: yup
    .string()
    .required('New password required')
    .min(8, 'Password must be at least 8 characters long'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match')
    .required('Confirm password required'),
});

const ForgotPassword: FC = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleRequestReset = async (username: string) => {
    setLoading(true);
    try {
      await resetPassword({ username });
      toast.success('Verification code sent to your email', {
        position: 'top-right',
      });
      setEmail(username);
      setStep(2);
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (
    username: string,
    confirmationCode: string,
    newPassword: string
  ) => {
    setLoading(true);
    try {
      await confirmResetPassword({
        username,
        confirmationCode,
        newPassword,
      });
      toast.success('Password reset successfully', {
        position: 'top-right',
      });
      navigate(RoutePath.LOGIN);
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await handleRequestReset(values.email);
    },
  });

  const codeFormik = useFormik({
    initialValues: {
      email,
      code: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: codeValidationSchema,
    onSubmit: async (values) => {
      await handleResetPassword(email, values.code, values.newPassword);
    },
  });

  return (
    <ScreenWrapper>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : step === 1 ? (
        <>
          <h2 className='mb-4 text-center'>Forgot Password</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className='mb-3'>
              <label htmlFor='email' className='form-label'>
                Email:
              </label>
              <input
                type='text'
                id='email'
                name='email'
                className='form-control'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='text-danger'>{formik.errors.email}</div>
              ) : null}
            </div>
            <button type='submit' className='btn btn-primary mt-3'>
              Request Password Reset
            </button>
          </form>
        </>
      ) : (
        <>
          <h2 className='mb-4 text-center'>Reset Password</h2>
          <form onSubmit={codeFormik.handleSubmit}>
            <div className='mb-3'>
              <label htmlFor='email' className='form-label'>
                Email:
              </label>
              <input
                type='text'
                id='email'
                name='email'
                className='form-control'
                value={email}
                disabled
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='code' className='form-label'>
                Verification Code:
              </label>
              <input
                type='text'
                id='code'
                name='code'
                className='form-control'
                onChange={codeFormik.handleChange}
                onBlur={codeFormik.handleBlur}
                value={codeFormik.values.code}
              />
              {codeFormik.touched.code && codeFormik.errors.code ? (
                <div className='text-danger'>{codeFormik.errors.code}</div>
              ) : null}
            </div>
            <div className='mb-3'>
              <label htmlFor='newPassword' className='form-label'>
                New Password:
              </label>
              <input
                type='password'
                id='newPassword'
                name='newPassword'
                className='form-control'
                onChange={codeFormik.handleChange}
                onBlur={codeFormik.handleBlur}
                value={codeFormik.values.newPassword}
              />
              {codeFormik.touched.newPassword && codeFormik.errors.newPassword ? (
                <div className='text-danger'>{codeFormik.errors.newPassword}</div>
              ) : null}
            </div>
            <div className='mb-3'>
              <label htmlFor='confirmPassword' className='form-label'>
                Confirm Password:
              </label>
              <input
                type='password'
                id='confirmPassword'
                name='confirmPassword'
                className='form-control'
                onChange={codeFormik.handleChange}
                onBlur={codeFormik.handleBlur}
                value={codeFormik.values.confirmPassword}
              />
              {codeFormik.touched.confirmPassword && codeFormik.errors.confirmPassword ? (
                <div className='text-danger'>{codeFormik.errors.confirmPassword}</div>
              ) : null}
            </div>
            <button type='submit' className='btn btn-primary mt-3'>
              Reset Password
            </button>
          </form>
        </>
      )}
    </ScreenWrapper>
  );
};

export default ForgotPassword;
