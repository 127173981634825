import React from 'react';
import { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { RoutePath } from './navigation/route-path';
import Login from './screens/Login';
import Welcome from './screens/Welcome';
import ChangePassword from './screens/ChangePassword';
import ForgotPassword from './screens/ForgotPassword';

import './App.css';

const App = () => (
  <Router>
    <div className='gradient-background'>
      <Routes>
        <Route path={RoutePath.LOGIN} element={<Login />} />
        <Route path={RoutePath.WELCOME} element={<Welcome />} />
        <Route path={RoutePath.CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path={RoutePath.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path='*' element={<Navigate to={RoutePath.LOGIN} />} />
      </Routes>
      <Toaster />
    </div>
  </Router>
);

export default App;
