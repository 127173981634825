import AWS from 'aws-sdk';
import { fetchAuthSession } from 'aws-amplify/auth';
import toast from 'react-hot-toast';
import { useState } from 'react';

const useAuthCredentials = () => {
  const [loading, setLoading] = useState(false);

  const getAuthCredentials = async () => {
    setLoading(true);
    try {
      const { credentials } = await fetchAuthSession({ forceRefresh: true });

      if (!credentials) {
        setLoading(false);
        return null;
      }

      const awsCredentials = new AWS.Credentials({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      });

      AWS.config.update({
        credentials: awsCredentials,
      });

      setLoading(false);
      return awsCredentials;
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
      });
      setLoading(false);
      return null;
    }
  };

  return { getAuthCredentials, loading };
};

export default useAuthCredentials;
