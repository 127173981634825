import React, { FC } from 'react';
import logo from '../assets/images/logo.svg';

interface ScreenWrapperProps {
  children: React.ReactNode;
}

export const ScreenWrapper: FC<ScreenWrapperProps> = ({ children }) => (
  <div className='d-flex justify-content-center align-items-center' style={{ width: '100vw' }}>
    <div className='form-container'>
      <div className='position-absolute top-0 start-0 p-4'>
        <img src={logo} alt='Logo' />
      </div>
      <div className='position-absolute bottom-0 start-0 p-4'>
        &copy; {new Date().getFullYear()} BlueLightAI - All Rights Reserved.
      </div>
      {children}
    </div>
  </div>
);
