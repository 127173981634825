import React, { useState, useEffect, FC } from 'react';
import AWS from 'aws-sdk';
import { signOut } from 'aws-amplify/auth';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { RoutePath } from '../navigation/route-path';
import { ScreenWrapper } from '../components/ScreenWrapper';
import useAuthCredentials from '../hooks/useAuthCredentials';

const FALLBACK_DOCUMENTATION_URL = 'https://docs.cobalt.dev.bluelightai.com/';

const Welcome: FC = () => {
  const navigate = useNavigate();
  const { getAuthCredentials, loading } = useAuthCredentials();

  const [packageName, setPackageName] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [awsCredentials, setAwsCredentials] = useState<AWS.Credentials | null>(null);

  useEffect(() => {
    const fetchPackageName = async () => {
      const credentials = await getAuthCredentials();
      if (!credentials) {
        navigate(RoutePath.LOGIN);
        return;
      }

      setAwsCredentials(credentials);

      const ssmClient = new AWS.SSM({
        region: process.env.REACT_APP_AWS_PROJECT_REGION as string,
      });

      ssmClient.getParameter(
        {
          Name: process.env.REACT_APP_AWS_SSM_PACKAGE_NAME as string,
        },
        (err, data) => {
          if (err) {
            toast.error(err.message, {
              position: 'top-right',
            });
            return;
          }

          if (data?.Parameter?.Value) {
            setPackageName(data.Parameter.Value);
          }
        }
      );
    };

    fetchPackageName();
  }, []);

  const downloadFile = (): void => {
    setDownloadComplete(false);
    setIsDownloading(true);

    const s3 = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME as string },
    });

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME as string,
      Key: packageName,
    };

    s3.getObject(params, function (err, data) {
      if (err) {
        toast.error(err.message, {
          position: 'top-right',
        });
        setIsDownloading(false);
        return;
      }

      // @ts-ignore
      const blob = new Blob([data.Body], { type: 'application/zip' });
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = packageName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);

      setIsDownloading(false);
      setDownloadComplete(true);
    });
  };

  const getDocumentationUrl = () => {
    const { protocol, host } = window.location;
    if (host.includes('localhost')) {
      return FALLBACK_DOCUMENTATION_URL;
    }
    return `${protocol}//docs.${host}`;
  };

  return (
    <ScreenWrapper>
      {loading && !awsCredentials ? (
        <div className='d-flex justify-content-center align-items-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <header className='d-flex justify-content-between align-items-center mb-4'>
            <h4>You have successfully logged in!</h4>
            <button
              type='button'
              onClick={() => signOut().then(() => navigate(RoutePath.LOGIN))}
              className='btn btn-link p-0'
              aria-label='Sign Out'
            >
              <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
          </header>
          <section>
            <a href={getDocumentationUrl()} target='_blank' rel='noopener noreferrer'>
              Cobalt User Guide
            </a>
          </section>
          <main className='text-center'>
            <div className='mt-5'>
              <button type='button' onClick={downloadFile} className='btn btn-primary btn-lg'>
                Download Cobalt
              </button>
              <div className='mt-2'>
                {isDownloading && (
                  <div className='d-flex align-items-center justify-content-center mt-3'>
                    <div className='spinner-border text-primary' role='status'>
                      <span className='visually-hidden'>Downloading...</span>
                    </div>
                    <span className='m-2'>Downloading...</span>
                  </div>
                )}
                {downloadComplete && (
                  <div className='alert alert-success mt-3' role='alert'>
                    Download Complete!
                  </div>
                )}
              </div>
            </div>
          </main>
        </>
      )}
    </ScreenWrapper>
  );
};

export default Welcome;
